@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;700&display=swap');

* {
    box-sizing: border-box;
}

html, body, #root {
    margin: 0;
    padding: 0;
    height: 100%;
}

#root {
    display: grid;
    grid-template-rows: 120px 1fr 5vh;
}

header {
    position: fixed;
    height: 120px;
    top: 0;
    right: 0;
    left: 0;
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    gap: 15px;
    padding: 0 15px;

    &:after {
        content: "";
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        height: 1px;
        background: rgb(255,255,255);
        background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(218,178,47,1) 15%, rgba(161,130,28,1) 50%, rgba(218,178,47,1) 85%, rgba(255,255,255,1) 100%); 
    }

    .logo-and-main-title {
        display: grid;
        grid-template-columns: 110px 1fr;
    
        .logo {
            align-self: center;

            .logo-element {
                fill: #a1821c;
            }
        }
        
        .main-title {
            align-self: center;

            h1 {
                font-size: 3vw;
                font-family: 'Ubuntu', sans-serif;
                font-weight: 700;
                margin: 0;
                color: #a1821c;
            }
        }
    }

    .click-to-call {
        align-self: center;
        justify-self: center;

        a {
            background-color: #4C5C68;
            color: #fff;
            text-decoration: none;
            padding: 15px;
            font-family: 'Ubuntu', sans-serif;
            font-weight: 400;
            font-size: 1.6rem;
            border-radius: 4px;
        }
    }

    .slogan {
        align-self: center;

        h2 {
            font-family: 'Ubuntu', sans-serif;
            font-weight: 700;
            font-size: 2vw;
            margin: 0;
            color: #a1821c;
            text-align: center;
        }
    }
}

.nav-and-main {
    position: fixed;
    top: 120px;
    right: 0;
    bottom: 5vh;
    left: 0;
    display: grid;
    grid-template-columns: 320px 1fr;
    gap: 15px;

    nav {
        position: relative;

        &:after {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            width: 1px;
            background: rgb(255,255,255);
            background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(218,178,47,1) 15%, rgba(161,130,28,1) 50%, rgba(218,178,47,1) 85%, rgba(255,255,255,1) 100%); 
        }

        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;

            li {
                margin: 15px;

                a {
                    display: grid;
                    grid-template-columns: 36px 1fr;
                    text-decoration: none;
                    font-family: 'Ubuntu', sans-serif;
                    font-weight: 400;
                    color: #a1821c;
                    font-weight: 700;
                    transition: all 0.4s ease-out;
                    align-items: center;
                    padding: 12px 8px;

                    &:hover, &.active {
                        background-color: #4C5C68;
                        color: #fff;
                        border-radius: 4px;
                    }
                }
            }
        }
    }
}

.main-content {
    overflow-y: scroll;
}

.page {
    padding: 15px 15px;

    .wp-block-media-text {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 15px;
        margin-bottom: 2rem;

        .wp-block-media-text__media {
            margin: 0;
            align-self: center;
            justify-self: center;

            img {
                border: 5px solid #fff;
                -webkit-box-shadow: 0px 0px 5px 5px rgba(76,92,104,0.5); 
                box-shadow: 0px 0px 5px 5px rgba(76,92,104,0.5);
            }
        }
    }

    h3 {
        position: relative;
        font-family: 'Ubuntu', sans-serif;
        font-weight: 700;
        font-size: 2vw;
        line-height: 1;
        padding-bottom: 1vw;
        color: #4C5C68;
        margin: 0 0 2rem 0;

        &:after {
            content: "";
            position: absolute;
            bottom: 0;
            right: 0;
            left: 0;
            height: 1px;
            background: rgb(255,255,255);
            background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(113,135,152,1) 15%, rgba(76,92,104,1) 50%, rgba(113,135,152,1) 85%, rgba(255,255,255,1) 100%);  
        }
    }

    p {
        font-family: 'Ubuntu', sans-serif;
        font-weight: 400;
        font-size: 1.2rem;
        line-height: 1.5;
    }

    .server-response {
        margin-bottom: 1rem;
        padding: 1rem;

        &.success {
            border: 1px solid #0c0;
            color: #0c0;
        }

        &.error {
            border: 1px solid #c00;
            color: #c00;
        }
    }

    form {
        display: flex;
        flex-direction: column;

        div {
            display: flex;
            flex-direction: column;
            margin-bottom: 1rem;

            label {
                font-family: 'Ubuntu', sans-serif;
                font-weight: 400;
                font-size: 1rem;
                line-height: 1.5;
                margin-bottom: 8px;
            }

            input, textarea {
                padding: 6px;
                border-radius: 4px;
                border: 1px solid #4C5C68;
            }

            textarea {
                height: 260px;
            }

            button {
                padding: 6px;
                margin: 0 20%;
                background-color: #a1821c;
                color: #fff;
                border: 0;
                border-radius: 4px;
                cursor: pointer;
                font-family: 'Ubuntu', sans-serif;
                font-weight: 400;
                font-weight: 700;
                transition: all 0.4s ease-out;
                padding: 12px 8px;

                &:hover, &.active {
                    background-color: #4C5C68;
                }
            }
        }
    }
}

footer {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    height: 5vh;
    display: grid;
    grid-template-columns: 1fr;
    
    &:before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        height: 1px;
        background: rgb(255,255,255);
        background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(218,178,47,1) 15%, rgba(161,130,28,1) 50%, rgba(218,178,47,1) 85%, rgba(255,255,255,1) 100%);         
    }

    h2 {
        font-family: 'Ubuntu', sans-serif;
        font-weight: 700;
        font-size: 2rem;
        align-self: center;
        justify-self: center;
        margin: 0;
        color: #a1821c;
    }    
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    header {
        grid-template-columns: 1fr auto 1fr 64px;

        .click-to-call {

            a {
                font-size: 2.4vw;
            }
        }

        .open-mobile-nav {
            align-self: center;
            justify-self: end;

            button {
                background-color: #4C5C68;
                color: #fff;
                text-decoration: none;
                padding: 15px;
                font-size: 1.6vw;
                border-radius: 4px;
                border: 0;
            }
        }
    }

    .nav-and-main {
        display: block;
        padding: 0 15px;
        overflow-y: scroll;
    }

    .mobile-nav {
        position: fixed;
        top: 0;
        left: -100%;
        width: 85%;
        height: 100%;
        background-color: #4C5C68;
        z-index: 999;
        transition: left 0.3s ease-in-out;

        &.open {
            left: 0;
        }

        nav {
            margin: 15px;
            padding: 0;

            ul {
                list-style-type: none;
                margin: 0;
                padding: 0;

                li {
                    margin-bottom: 15px;

                    a {
                        font-family: 'Ubuntu', sans-serif;
                        display: grid;
                        grid-template-columns: 48px 1fr;
                        text-decoration: none;
                        color: #fff;
                        border: 1px solid #fff;
                        border-radius: 4px;
                        font-size: 3vh;
                        padding: 2vh;

                        &.active {
                            background-color: #fff;
                            color: #4C5C68;
                        }
                    }
                }
            }
        }
    }

    .page {
        h3 {
            font-size: 3vw
        }
    }
}

@media screen and (max-width: 767px) {
    #root {
        grid-template-rows: 70px 1fr 5vh;
    }

    header {
        grid-template-columns: auto auto auto;
        max-height: 70px;
        padding: 0 8px;

        .logo-and-main-title {
            grid-template-columns: 70px 1fr;

            .logo svg {
                max-width: 100%;
                max-height: 45px;
            }

            .main-title {
                h1 {
                    font-size: 3vw;
                }
            }
        }        

        .click-to-call {

            a {
                font-size: 4vw;
                padding: 10px;
            }
        }

        .open-mobile-nav {
            align-self: center;
            justify-self: end;

            button {
                background-color: #4C5C68;
                color: #fff;
                text-decoration: none;
                padding: 8px;
                font-size: 4vw;
                border-radius: 4px;
                border: 0;
            }
        }
    }

    .nav-and-main {
        top: 70px;
        bottom: 5vh;
        height: calc(100% - 70px - 5vh);
        overflow-y: scroll;
        display: block;
        padding: 0 15px;
    }

    .mobile-nav {
        position: fixed;
        top: 0;
        left: -100%;
        width: 85%;
        height: 100%;
        background-color: #4C5C68;
        z-index: 999;
        transition: left 0.3s ease-in-out;

        &.open {
            left: 0;
        }

        nav {
            margin: 15px;
            padding: 0;

            ul {
                list-style-type: none;
                margin: 0;
                padding: 0;

                li {
                    margin-bottom: 15px;

                    a {
                        font-family: 'Ubuntu', sans-serif;
                        display: grid;
                        grid-template-columns: 48px 1fr;
                        text-decoration: none;
                        color: #fff;
                        border: 1px solid #fff;
                        border-radius: 4px;
                        font-size: 2.5vh;
                        padding: 2vh;

                        &.active {
                            background-color: #fff;
                            color: #4C5C68;
                        }
                    }
                }
            }
        }
    }

    .page {
        padding: 2rem;
        .wp-block-media-text {
            display: flex;
            flex-direction: column-reverse;
            margin-bottom: 2rem;
    
            .wp-block-media-text__media {
                margin: 0;
                align-self: center;
                justify-self: center;
    
                img {
                    border: 5px solid #fff;
                    -webkit-box-shadow: 0px 0px 5px 5px rgba(76,92,104,0.5); 
                    box-shadow: 0px 0px 5px 5px rgba(76,92,104,0.5);
                }
            }
        }

        .wp-block-media-text.has-media-on-the-right {
            flex-direction: column;
        }

        h3 {
            font-size: 2.6vh;
        }
        
    }    

    footer {
        h2 {
            font-size: 3vw;
        }
    }
}
  